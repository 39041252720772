import { ReloadOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Checkbox,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  IconButton
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { message } from "antd";
import _ from "lodash";
import queryString from "query-string";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import Captcha from "../../../components/Captcha";
import {
  IconRegisterMobile,
  IconRegisterV,
  UserIcon
} from "../../../components/Icon";
import { CLEAR_REDUX } from "../../../constant";
import { CountryMapList } from "../../../constant/CountryMap";
import {
  companyNameYupValidate,
  emailYupValidate,
  passwordYupValidate,
  urlYupValidate,
  userNameYupValidate,
  countryCodeYupValidate
} from "../../../constant/validates";
import { RegisterForm } from "../../../interface/RegisterForm";
import { CountryType } from "../../../interface/CountryType";
import { handleRegister, handleSetLoading } from "../../../redux/action/auth";
import { getImgValiCode } from "../../../utility/common/getImgValidCode";
import { useAppSelector } from "../../../utility/hooks/useSelector";
import { useMediaQuery } from "react-responsive";
import {
  AuthForm,
  AuthPage,
  CaptchaStyled,
  HeaderTitle,
  ImageWrapper,
  LogoWrapper,
  Navigation,
  PlatformItem,
  SubmitButton,
  Text,
  Title
} from "../Auth";
import ForgotPassword from "../ForgotPassword";
import SignUpProgressModal from "../../../components/Modal/SignUpModal/SignUpProgressModal";
import SignUpInfoModal from "../../../components/Modal/SignUpModal/SignUpInfoModal";
import mediaQueries from "../../../utility/responsive";
import { Helmet } from "react-helmet";
import { getIpLocalMachine } from "../../../redux/action/api/profile";
import DialogError from "./DialogError";
import { REGISTER_ERROR_IP } from "../../../redux/actionType";
import { handleGetPlatformList } from "../../../redux/action/platforms";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/swiper.min.css";
import User from "../../../components/Icon/User";
import Company from "../../../components/Icon/Company";
import Email from "../../../components/Icon/Email";
import Password from "../../../components/Icon/Password";
import Website from "../../../components/Icon/Website";
import Country from "../../../components/Icon/Country";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Marquee from "react-fast-marquee";
import PaymentRedirectGlobal from "../../../constant/PaymentRedirectGlobal";

const schema = yup.object().shape({
  username: userNameYupValidate.username,
  websiteURL: urlYupValidate.url,
  password: passwordYupValidate.password,
  email: emailYupValidate.email,
  companyName: companyNameYupValidate.companyName,
  country_code: countryCodeYupValidate.country_code
});

const NoteStyled = styled.p`
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
  color: red;
`;

const TextBottom = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: 0.15px;
  color: #fff;
  margin-bottom: 10px;
  display: block;

  ${mediaQueries.nav} {
    text-align: start;
  }
`;
const ContentBottom = styled.div`
  ${mediaQueries.nav} {
    margin-bottom: 8px;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  z-index: 1;
`;

const WhiteBorderTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #536283;
  }
  & input.MuiInput-input {
    background-color: transparent !important;
  }
  & .MuiInputLabel-asterisk {
    color: #fff;
  }

  & label {
    color: #fff;
    font-weight: 500;
  }
  & label.Mui-focused {
    color: #fff;
  }
  & .MuiInput-underline:after {
    border-color: #fff;
  }
  & .MuiInput-underline:hover:before {
    border-color: #fff;
  }
  & .MuiInput-underline:before {
    border-color: #fff;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #fff;
    }
    &:hover fieldset {
      border-color: #fff;
    }
    &.Mui-focused fieldset {
      border-color: #fff;
    }
  }
`;

const WhiteBorderMenuItem = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #536283;
  }
`;

const CapchaTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #536283;
  }
  & label {
    color: #fff;
    font-weight: 500;
  }
  & label.Mui-focused {
    color: #fff;
  }
  & .MuiInput-underline:after {
    border-color: #fff;
  }
  & .MuiInput-underline:hover:before {
    border-color: #fff;
  }
  & .MuiInput-underline:before {
    border-color: #fff;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #fff;
    }
    &:hover fieldset {
      border-color: #fff;
    }
    &.Mui-focused fieldset {
      border-color: #fff;
    }
  }

  ${mediaQueries.nav} {
    .MuiOutlinedInput-root {
      font-size: 14px;
    }

    .MuiInputLabel-root {
      font-size: 12px;
    }
  }
`;

const TAndCWrapper = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

const CheckBoxStyled = styled(Checkbox)`
  ${mediaQueries.nav} {
    padding: 0px;
    margin-right: 2px;
  }
`;

const ReloadOutlinedStyled = styled(ReloadOutlined)`
  width: 32px;
  height: 32px;
  padding: 6px;
  color: #fff;
  margin-top: 10px;
  margin-right: 16px;
  margin-left: 25px;

  ${mediaQueries.nav} {
    margin-right: 4px;
    margin-left: 4px;
  }
`;

const ForgotPasswordIconStyled = styled(LockOutlinedIcon)`
  filter: invert(39%) sepia(10%) saturate(1595%) hue-rotate(184deg)
    brightness(88%) contrast(82%);
`;

const RegisterButton = styled(SubmitButton)`
  position: relative;
  width: 100%;
  height: 48px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 11.058px;
  background: #fff;
  box-shadow: 0px 7.74074125289917px 16.587303161621094px 0px
    rgba(80, 181, 104, 0.1);
  color: #51b380;
  text-align: center;
  text-transform: uppercase;
`;

const CopyRightText = styled.span`
  color: #979da0;
  margin-top: 70px;

  ${mediaQueries.nav} {
    margin-top: 20vh;
  }
`;

const ContainerHeader = styled.div`
  display: flex;
  padding: 30px 30px 0px 30px;
  align-items: center;
  ${mediaQueries.nav} {
    padding: 0px;
  }
`;

const LeftDivHeader = styled.div`
  width: 10%;
  ${mediaQueries.nav} {
    width: 40%;
  }
`;
const CenterDivHeader = styled.div`
  width: 65%;
  ${mediaQueries.nav} {
    width: 10%;
  }
`;
const RightTextHeader = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  ${mediaQueries.nav} {
    font-size: 14px;
  }
`;
const RightDivHeader = styled.div``;
const ContainerRegister = styled.div`
  display: flex;
  width: 100%;
  gap: 100px;

  @media (max-width: 1200px) {
    gap: 50px;
  }

  ${mediaQueries.nav} {
    flex-direction: column;
    padding: 0px;
    align-items: center;
    gap: 0;
  }
`;
const RightContainer = styled.div`
  padding: 30px;
  width: auto;
  max-width: 630px;
  border-radius: 33.175px;
  background: #51b380;
  ${mediaQueries.nav} {
    margin-top: 20px;
    max-width: 100%;
  }
`;
const LeftContainer = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    max-width: 700px;
  }
  @media (max-width: 1200px) {
    max-width: 450px;
  }

  ${mediaQueries.nav} {
    margin: 0 auto;
    max-width: 100%;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  height: 130px;
  width: auto;
  padding: 30px;
  background-color: #52b380;
  align-items: center;
  ${mediaQueries.nav} {
    margin-top: 20px;
    padding: 15px;
  }
`;
const EclipseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background-color: #45976c;
  width: 80px;
  height: 80px;
  margin-right: 30px;
  ${mediaQueries.nav} {
    margin-right: 10px;
    width: 98px;
    height: 75px;
  }
`;

const TextPolicy = styled(Typography)`
  font-size: 19px;
  font-weight: bold;
  margin-left: 20px;
  text-align: start;
  ${mediaQueries.nav} {
    margin-top: 10px;
    font-size: 13px;
    text-align: initial;
    margin-left: 10px;
  }
`;

const ImagePolicy = styled(IconRegisterV)`
  margin-top: 10px;
`;
const ContainerDivPolicy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  ${mediaQueries.nav} {
    justify-content: flex-start;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const BottomCard = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  ${mediaQueries.nav} {
    font-size: 13px;
  }
`;
const BottomCard2 = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
  color: white;
  ${mediaQueries.nav} {
    font-size: 13px;
  }
`;

export function Register() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 968px)" });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSuccess = useAppSelector((state) => state.auth.isSuccess); //get register status
  const isLoading = useAppSelector((state) => state.auth.isLoading); //get register status
  const { userData } = useAppSelector((state) => state.auth);
  const isOpenModalError = useAppSelector(
    (state) => state.auth.isOpenModalError
  ); // toggle dialog error
  const messageErrorIp = useAppSelector((state) => state.auth.messageErrorIp); // message error Ip
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [rightCode, setRightCode] = useState(""); //captcha valid
  const [isCaptchaError, setIsCaptchaError] = useState(false); //show error when enter captcha invalid
  const [myCaptcha, setMyCaptcha] = useState(""); //captcha get form input
  const [tab, setTab] = useState(0);
  const [openProgress, setOpenProgress] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [filteredPlatforms, setFilteredPlatforms] = useState<any>([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { platformList } = useAppSelector((state) => state.platforms);

  const link = `${process.env.REACT_APP_WEB_CLIENT_URL}/terms-and-conditions`;
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    control
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
      websiteURL: "",
      username: "",
      companyName: "",
      country_code: "",
    },
    resolver: yupResolver(schema)
  });

  const [ip, setIp] = useState<string>("");
  const titleObj = queryString.parse(location.search);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  // get websiteURL from claim
  useEffect(() => {
    const searchObj = queryString.parse(location.search);
    if (searchObj?.webUrl && searchObj?.companyName) {
      // setValue("websiteURL", Array.isArray(searchObj.webUrl)
      //   ? searchObj.webUrl[0]
      //   : searchObj.webUrl);
      // setValue("companyName", Array.isArray(searchObj.webUrl)
      //   ? searchObj.companyName[0]
      //   : searchObj.companyName.toString());
      reset({
        // set default companyWebsite field when claim profile
        websiteURL: Array.isArray(searchObj.webUrl)
          ? searchObj.webUrl[0] ?? ""
          : searchObj.webUrl ?? "",
        companyName: Array.isArray(searchObj.companyName)
          ? searchObj.companyName[0] ?? ""
          : searchObj.companyName ?? ""
      });
    }
  }, [location.search, reset]);

  //get ip machine guest
  useEffect(() => {
    getIpLocalMachine()
      .then((res) => {
        setIp(res?.data?.ip ?? "");
      })
      .catch((err) => console.log("err"));
  }, []);

  const handleRegisterSubmit = (values: RegisterForm) => {
    const countryName = CountryMapList.find((item: any) => {
      if (item.code === values.country_code) {
        return item.name;
      }
    });
    if (!isChecked) message.error("Please, agree to T&C's");
    else {
      const infoRegister = {
        name: values.username.trim(),
        email: values.email.trim(),
        password: values.password,
        ip: ip,
        company: [
          {
            name: values.companyName.trim(),
            url_display: values.websiteURL.trim(),
            country_code: values.country_code.trim()
          }
        ]
      };
      dispatch(handleSetLoading());
      dispatch(handleRegister(infoRegister));
    }
  };

  // useEffect(() => {
  //   if (isSuccess && !openProgress) {

  //     // setOpenPaymentModal(true);
  //     // setOpenProgress(true);
  //     }
  // }, [isSuccess, openProgress]);

  const handleReloadCaptCha = () => {
    if (canvasRef.current) {
      setRightCode(getImgValiCode(canvasRef.current));
    }
  };

  const handleCaptchaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMyCaptcha(e.target.value);
    setIsCaptchaError(false);
  };

  useEffect(() => {
    if (canvasRef.current) {
      setRightCode(getImgValiCode(canvasRef.current));
    }
  }, []);

  const handleCloseSignUpInfo = () => {
    dispatch({ type: CLEAR_REDUX });
    setOpenInfoModal(false);
    navigate("/login");
  };

  const handleOpenSignUpInfo = () => {
    setOpenInfoModal(true);
    navigate("/success");
    setTimeout(() => {
      handleCloseSignUpInfo();
    }, 5000);
  };

  useEffect(() => {
    (async () => {
      await dispatch(
        handleGetPlatformList({
          page: 1,
          page_size: 50
        })
      );
    })();
  }, []);

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WEB_CLIENT_URL}`;
  };

  useEffect(() => {
    const objectToMove = platformList.find((item) => item.name === "Facebook");
    const currentIndex = platformList.findIndex(
      (item) => item.name === "Facebook"
    );

    if (currentIndex > -1 && objectToMove) {
      platformList.splice(currentIndex, 1); // Remove the object from its current position
      const newIndex = 5; // Define the new index where you want to place the object
      platformList.splice(newIndex, 0, objectToMove); // Insert the object at the new index
    }

    setFilteredPlatforms(platformList);
  }, [platformList]);

  return (
    <>
      <Helmet>
        <title>
          {`Register | ${
            !_.isEmpty(titleObj)
              ? Array.isArray(titleObj.companyName)
                ? titleObj.companyName[0]
                : titleObj.companyName
              : "VerifyTrusted"
          }`}{" "}
        </title>
        <meta
          property="og:title"
          content={`Register | ${
            !_.isEmpty(titleObj)
              ? Array.isArray(titleObj.companyName)
                ? titleObj.companyName[0]
                : titleObj.companyName
              : "VerifyTrusted"
          }`}
        />
        <meta
          name="description"
          content="Start showcasing your best reviews in one place to boost trust and skyrocket your sales! Sign up now and unlock the power of customer testimonials. Join us today!"
        />
        <meta
          property="og:description"
          content="Start showcasing your best reviews in one place to boost trust and skyrocket your sales! Sign up now and unlock the power of customer testimonials. Join us today!"
        />

        {process.env.REACT_APP_WEB_URL ===
          "https://admin.verifytrusted.com" && (
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '926842531820237');
            fbq('track', 'PageView');
          `}
          </script>
        )}

        {process.env.REACT_APP_WEB_URL ===
          "https://admin.verifytrusted.com" && (
          <noscript>
            {`<img
            height="1"
            width="1"
            style= "display:none"
            src="https://www.facebook.com/tr?id=926842531820237&ev=PageView&noscript=1"
            alt=""
          />`}
          </noscript>
        )}
      </Helmet>
      <AuthPage
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative"
        }}
      >
        {isLoading && (
          <>
            <CircularProgress
              size={50}
              sx={{
                zIndex: 2,
                position: "absolute",
                top: "38%"
              }}
            />
            <Overlay></Overlay>
          </>
        )}
        {tab === 0 ? (
          <>
            <AuthForm onSubmit={handleSubmit(handleRegisterSubmit)} noValidate>
              {/* <ContainerHeader>
                <LeftDivHeader>
                  <img
                    src="/assets/default-logo/logo-dark.png"
                    width="250"
                    height="50"
                  />
                </LeftDivHeader>
                <CenterDivHeader />
                <RightDivHeader>
                  <RightTextHeader
                    style={{ marginTop: 30, fontWeight: "bold" }}
                  >
                    Already got an account?{" "}
                    <a
                      href="/login"
                      target="_blank"
                      style={{
                        color: "#52b380",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Login
                    </a>
                  </RightTextHeader>
                </RightDivHeader>
              </ContainerHeader> */}
              {/* <NoteStyled>
                *Please ensure you are an employee of this company to rightfully claim it.
              </NoteStyled> */}
              <ContainerRegister>
                <LeftContainer>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <LogoWrapper onClick={handleRedirect}>
                      <img
                        src="/assets/default-logo/logo-dark.png"
                        width="250"
                        height="50"
                        alt="logo"
                      />
                    </LogoWrapper>

                    <HeaderTitle>
                      Boost Trust and skyrocket 🚀 your <span> sales! </span> 📈
                    </HeaderTitle>

                    <ImageWrapper>
                      <img
                        src="/assets/images/sign-up/signup-img.png"
                        width="100%"
                        height="100%"
                        alt="singup-img"
                      />
                    </ImageWrapper>

                    <Text color="#757575" fontSize="24px" margin="0 0 20px">
                      Let’s get you started showcasing all your best reviews in
                      one place.
                    </Text>

                    <Marquee className="marqueeContainer">
                      {filteredPlatforms?.length > 0 &&
                        filteredPlatforms.map(
                          (item: any, idx: number) =>
                            item.logo && (
                              <PlatformItem key={idx}>
                                <img
                                  src={item.logo}
                                  width="30"
                                  height="30"
                                  alt="platform-icon"
                                />
                              </PlatformItem>
                            )
                        )}
                    </Marquee>

                    <Text color="#C8C8C8" fontSize="22px" margin="35px 0 0">
                      Embed Reviews From 50+ Platforms
                    </Text>
                  </div>
                </LeftContainer>
                <RightContainer>
                  <WhiteBorderTextField
                    defaultValue=""
                    {...register("username")}
                    helperText={errors.username?.message}
                    error={isSubmitted && !_.isEmpty(errors.username?.message)}
                    fullWidth
                    placeholder={"Enter your full name"}
                    label={"Full Name "}
                    required
                    style={{ marginBottom: 24, color: "#0009" }}
                    variant="standard"
                    InputProps={{
                      style: { color: "#fff", padding: "10px 0" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <User />
                        </InputAdornment>
                      )
                    }}
                  />
                  <Controller
                    name="companyName"
                    control={control}
                    render={({ field }) => (
                      <WhiteBorderTextField
                        {...field}
                        {...register("companyName")}
                        helperText={errors.companyName?.message}
                        error={
                          isSubmitted && !_.isEmpty(errors.companyName?.message)
                        }
                        fullWidth
                        label={"Company Name"}
                        placeholder={"Enter your company name"}
                        style={{ marginBottom: 24, color: "#0009" }}
                        required
                        variant="standard"
                        InputProps={{
                          style: { color: "#fff", padding: "10px 0" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Company />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <WhiteBorderTextField
                    {...register("email")}
                    helperText={errors.email?.message}
                    error={isSubmitted && !_.isEmpty(errors.email?.message)}
                    fullWidth
                    label={"Email Address"}
                    placeholder={"Enter your email adress"}
                    required
                    style={{ marginBottom: 24, color: "#0009" }}
                    variant="standard"
                    InputProps={{
                      style: { color: "#fff", padding: "10px 0" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />
                  <WhiteBorderTextField
                    {...register("password")}
                    helperText={errors.password?.message}
                    error={isSubmitted && !_.isEmpty(errors.password?.message)}
                    fullWidth
                    id="outlined-password-input"
                    label="Password"
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    required
                    style={{ marginBottom: 24, color: "#0009" }}
                    variant="standard"
                    InputProps={{
                      style: { color: "#fff", padding: "10px 0" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Password />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ color: "#fff" }} />
                            ) : (
                              <Visibility sx={{ color: "#fff" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Controller
                    name="websiteURL"
                    control={control}
                    render={({ field }) => (
                      <WhiteBorderTextField
                        {...field}
                        {...register("websiteURL")}
                        helperText={errors.websiteURL?.message}
                        error={
                          isSubmitted && !_.isEmpty(errors.websiteURL?.message)
                        }
                        fullWidth
                        placeholder="Enter your website URL"
                        required
                        label="Website URL"
                        style={{ marginBottom: 24, color: "#0009" }}
                        variant="standard"
                        InputProps={{
                          style: { color: "#fff", padding: "10px 0" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Website />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <WhiteBorderTextField
                    {...register("country_code")}
                    label="Country"
                    fullWidth
                    helperText={errors.country_code?.message}
                    error={
                      isSubmitted && !_.isEmpty(errors.country_code?.message)
                    }
                    select
                    SelectProps={{
                      IconComponent: () => <KeyboardArrowDownIcon />,
                      defaultValue: "US"
                    }}
                    style={{ marginBottom: 24, color: "#0009" }}
                    variant="standard"
                    InputProps={{
                      style: {
                        color: "#fff",
                        padding: "10px 0",
                        textAlign: "left"
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Country />
                        </InputAdornment>
                      )
                    }}
                  >
                    {CountryMapList.map((item, idx) => (
                      <MenuItem value={item.code} key={idx}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </WhiteBorderTextField>

                  <CaptchaStyled>
                    <div className="captcha-form-wrap">
                      {/* <div className="captcha-form">
                        <Captcha
                          ref={canvasRef}
                          isCaptchaError={isCaptchaError}
                        />
                        <ReloadOutlinedStyled
                          className="reload-icon"
                          onClick={handleReloadCaptCha}
                        />
                        <div className="captcha-input-wrap">
                          <CapchaTextField
                            label="Enter Captcha Code"
                            onChange={handleCaptchaChange}
                            value={myCaptcha}
                            fullWidth
                            style={{ color: "#0009" }}
                            variant="standard"
                            InputProps={{
                              style: {
                                color: "#fff",
                                padding: "5px 0",
                                textAlign: "left",
                              },
                            }}
                          />
                          {isCaptchaError && (
                            <p className="error">captcha is invalid</p>
                          )}
                        </div>
                      </div> */}

                      <ContentBottom>
                        {/* <TextBottom>
                          The picture contains 6 characters
                        </TextBottom> */}

                        <TAndCWrapper>
                          {/* <CheckBoxStyled
                            checked={isChecked}
                            onChange={() => setIsChecked((state) => !state)}
                            sx={{
                              color: "#fff",
                              "&.Mui-checked": {
                                color: "#fff",
                              },
                            }}
                          ></CheckBoxStyled>

                          <span style={{ color: "#fff", fontSize: "14px", marginRight: '5px' }}>
                            By creating an account or logging in, you agree to
                            our 
                            <a
                              style={{ fontWeight: "bold", color: "#fff", marginLeft : '5px' }}
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            > 
                              Terms & Conditions
                            </a>
                          </span> */}

                          <FormControlLabel
                            control={
                              <CheckBoxStyled
                                checked={isChecked}
                                onChange={() => setIsChecked((state) => !state)}
                                sx={{
                                  color: "#fff",
                                  "&.Mui-checked": {
                                    color: "#fff"
                                  }
                                }}
                              />
                            }
                            label="By creating an account or logging in, you agree to
                          our Terms & Conditions"
                            sx={{
                              "&	.MuiFormControlLabel-label": {
                                color: "#fff",
                                fontSize: "14px",
                                marginLeft: "5px"
                              },
                              "@media (max-width: 600px)": {
                                alignItems: "flex-start"
                              }
                            }}
                          />
                        </TAndCWrapper>
                      </ContentBottom>
                    </div>
                  </CaptchaStyled>
                  <RegisterButton disabled={isLoading}>
                    Create Account
                  </RegisterButton>

                  <RightDivHeader>
                    <RightTextHeader style={{ marginTop: 30 }}>
                      Already got an account?{" "}
                      <a
                        href="/login"
                        target="_blank"
                        style={{
                          color: "#fff",
                          textDecoration: "underline"
                        }}
                      >
                        Login
                      </a>
                    </RightTextHeader>
                  </RightDivHeader>
                  {/* <Grid style={{ marginTop: 15 }}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <Grid item>
                        <ForgotPasswordIconStyled />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "1rem",
                            cursor: "pointer",
                            color: "#536283",
                          }}
                          onClick={() => setTab(1)}
                        >
                          Forgot password?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </RightContainer>
              </ContainerRegister>
            </AuthForm>
            {/* <Navigation style={{ fontSize: 16, cursor: 'default' }}>
              I have an account.{" "}
              <Link to="/login" style={{ color: '#fff', fontWeight: 'bold' }}>
                Login here
              </Link>
            </Navigation>
            <CopyRightText>
              © 2022 VerifyTrusted.
            </CopyRightText> */}
          </>
        ) : (
          <ForgotPassword handleChangeTab={setTab} changeForm={0} />
        )}
        {openPaymentModal && userData && (
          <PaymentRedirectGlobal userData={userData} />
        )}
        <SignUpProgressModal
          isSuccess={isSuccess}
          onClose={setOpenProgress}
          isOpen={openProgress}
          isLoading={isLoading}
          openInfoModal={handleOpenSignUpInfo}
        />
        {/* <SignUpInfoModal
          isOpen={openInfoModal}
          isSuccess={isSuccess}
          onClose={handleCloseSignUpInfo}
        /> */}
      </AuthPage>
      <DialogError
        isOpenModal={isOpenModalError}
        handleClose={() => {
          dispatch({ type: REGISTER_ERROR_IP });
        }}
        ip={ip}
        messageErrorIp={messageErrorIp}
      />
    </>
  );
}
